<template>
  <div><coming-soon /></div>
</template>

<script>
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}tutorial`
};
</script>

<style lang="scss" scoped></style>
